import React from 'react';
import Paper from '@mui/material/Paper';
import { Stack, TablePagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UserTable from 'content/features/user-maintainer/components/UserTable';
import { setPagination } from 'content/features/user-maintainer/userMaintainer.actions';

function UserList({ rows }) {
  const dispatch = useDispatch();
  const { page, pageSize, total } = useSelector((state) => state.userMaintainer.pagination);
  const handleChangePage = (event, newPage) => {
    dispatch(setPagination({ name: 'page', value: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPagination({ name: 'pageSize', value: parseInt(event.target.value, 10) }));
  };

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <UserTable rows={rows} />
      <Stack alignItems="center">
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => `${from}—${to} de ${count}`}
          component="div"
          count={total}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Paper>
  );
}

export default UserList;
