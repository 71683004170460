import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, Grid, Select, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import BeneficiaryRequestSkeleton from 'content/features/beneficiary-requests/components/BeneficiaryRequestsSkeleton';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import { AccountCircle } from '@mui/icons-material';
import UserList from 'content/features/user-maintainer/components/UserList';
import { formatLettersAndSpaces, formatOnlyNumbers } from 'utils/helper';
import {
  clearFilters,
  fetchUserList,
  setControl,
} from 'content/features/user-maintainer/userMaintainer.actions';
import UserFilter from 'content/features/user-maintainer/components/UserFilter';
import { LoadingButton } from '@mui/lab';
import useForm from 'hooks/useForm';
import { validateEmail, validatePassword } from 'utils/functions';
import { createUser } from 'content/features/authentication/register/register.actions';

function UserMaintainerAdmin() {
  const rows = useSelector((state) => state.userMaintainer.users);
  const dispatch = useDispatch();
  const { loaders, pagination, controls } = useSelector((state) => state.userMaintainer);

  useEffect(() => {
    dispatch(fetchUserList({ controls, pagination }));
  }, [pagination.page, pagination.pageSize]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    const actions = {
      name: (val) => formatLettersAndSpaces(val),
      userId: (val) => formatOnlyNumbers(val),
    };

    const action = actions[name];
    const newValue = action ? action(value) : value;

    dispatch(setControl({ name, value: newValue }));
  };

  const handleClear = () => {
    dispatch(clearFilters());
    const clearFiltersData = {
      userId: '',
      name: '',
    };
    dispatch(fetchUserList({ controls: clearFiltersData, pagination }));
  };

  const handleSearch = () => {
    dispatch(fetchUserList({ controls, pagination }));
  };
  /* Register Form And Modal */
  const initialVariables = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    type: '',
  };
  const [variables, setVariables] = useState(initialVariables);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setVariables(initialVariables);
  };

  const handleForm = useCallback((name, value) => {
    setVariables((prevControls) => ({
      ...prevControls,
      [name]: value,
    }));
  }, []);

  const { email, name, password, repeatPassword } = variables;

  const { onChange, onSubmit, errors } = useForm(variables, null, {
    onChange: handleForm,
    validations: {
      name: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Nombre requerido',
        },
      ],
      email: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          // @ts-ignore
          check: (value) => validateEmail(value).check,
          message: 'Correo electrónico inválido',
        },
      ],
      password: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Contraseña requerida',
        },
        {
          check: (value) => validatePassword(value).check,
          message: `${validatePassword(password).message}`,
        },
      ],
      repeatPassword: [
        {
          check: (value) => value?.trim()?.length > 0,
          message: 'Confirmación de contraseña requerida',
        },
        {
          check: (value) => validatePassword(value).check,
          message: `${validatePassword(repeatPassword).message}`,
        },
        {
          check: (value) => value === password,
          message: 'Las contraseñas deben ser iguales',
        },
      ],
    },
  });

  const submitRegisterUser = async (e) => {
    e.preventDefault();
    setLoading(true); // Inicia el estado de carga

    await onSubmit(async () => {
      try {
        await dispatch(
          createUser({
            name: variables.name,
            email: variables.email,
            password: variables.password,
            type: variables.type,
          }),
        );
        setVariables(initialVariables);
        handleClose();
      } catch (error) {
        console.error('Error al crear usuario:', error);
      } finally {
        setLoading(false);
      }
    });
  };

  return loaders.initial ? (
    <BeneficiaryRequestSkeleton />
  ) : (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <MainTitle
        title="Mantenedor de usuarios"
        subtitle="Administra a los usuarios de la plataforma. Puedes agregar nuevos, editar a los ya existentes o eliminar si es necesario."
        icon={<AccountCircle sx={{ fontSize: 40, marginRight: 1, color: '#86BC25' }} />}
      />
      <Grid item xs={12}>
        <UserFilter
          controls={controls}
          onChange={handleChange}
          handleClear={handleClear}
          handleSearch={handleSearch}
        />
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            style={{ marginTop: '20px' }}>
            Añadir Usuario
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <form onSubmit={submitRegisterUser}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={10} textAlign="center" sx={{ mt: 3 }}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '32.68px',
                  }}>
                  Agregar usuarios
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Nombre"
                  key="name"
                  id="name"
                  name="name"
                  value={name}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.name[0]}
                  helperText={errors.name[0]}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Correo electrónico"
                  key="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  fullWidth
                  error={!!errors.email[0]}
                  helperText={errors.email[0]}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Contraseña"
                  key="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  type="password"
                  fullWidth
                  error={!!errors.password[0]}
                  helperText={errors.password[0]}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  label="Repetir contraseña"
                  key="repeatPassword"
                  id="repeatPassword"
                  name="repeatPassword"
                  value={variables.repeatPassword}
                  type="password"
                  onChange={onChange}
                  fullWidth
                  error={!!errors.repeatPassword[0]}
                  helperText={errors.repeatPassword[0]}
                />
              </Grid>
              <Grid item xs={10}>
                <Select
                  fullWidth
                  native
                  name="type"
                  value={variables.type}
                  onChange={(e) => handleForm('type', e.target.value)}>
                  <option value="" disabled selected>
                    Selecciona un rol
                  </option>
                  <option value="beneficiary">Beneficiario</option>
                  <option value="operator">Operador</option>
                  <option value="admin">Administrador</option>
                </Select>
              </Grid>
              <Grid item xs={10}>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                  disabled={
                    Object.values(variables).some((value) => value === '') ||
                    Object.values(errors).some((error) => error.length > 0)
                  }>
                  Crear usuario
                </LoadingButton>
              </Grid>
              <Grid item xs={10}>
                <Button
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                  fullWidth
                  variant="outlined"
                  size="large"
                  onClick={handleClose}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      <Grid item xs={12}>
        <UserList rows={rows} />
      </Grid>
    </Grid>
  );
}

export default UserMaintainerAdmin;
