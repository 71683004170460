import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';

import BeneficiaryView from 'content/shared/Views/BeneficiaryView';
import PaymentMethodView from 'content/shared/Views/PaymentMethodView';
import PersonalDocumentsView from 'content/shared/Views/PersonalDocumentsView';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import { fetchBeneficiaryRequestDetail } from 'content/features/beneficiary-request-detail/beneficiaryRequestDetail.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBackIosNew } from '@mui/icons-material';
import BeneficiaryRequestDetailSkeleton from 'content/features/beneficiary-request-detail/components/BeneficiaryRequestDetailSkeleton';
import SectionTitle from 'content/shared/SectionTitle/SectionTitle';
import VehicleView from 'content/shared/Views/VehicleView';
import ColorCodedChip from 'content/shared/ColorCodedChip/ColorCodedChip';

// eslint-disable-next-line complexity
function BeneficiaryRequestDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { controls, loaders, request } = useSelector((state) => state.beneficiaryRequestDetail);
  const { error } = controls;
  const {
    conciliationRequestId,
    vehicleLicensePlate,
    vehicleVin,
    vehicleBrand,
    vehicleModel,
    vehicleYear,
    personTaxNumber,
    personNames,
    personPaternalSurname,
    personMaternalSurname,
    personAddress,
    personRegion,
    personCity,
    personCommune,
    personPhone,
    personEmail,
    accountPaymentMethod,
    accountHolderName,
    accountHolderTaxNumber,
    accountBank,
    accountType,
    accountNumber,
    frontIdCard,
    backIdCard,
    carInscription,
    requestStatus,
  } = request;

  useEffect(() => {
    dispatch(fetchBeneficiaryRequestDetail({ id }));
  }, [id]);

  useEffect(() => {
    if (error) {
      navigate('/requests');
    }
  }, [error]);

  return loaders.initial ? (
    <BeneficiaryRequestDetailSkeleton />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mt: 2.5 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIosNew color="primary" />}
          sx={{
            pl: 0,
            color: 'black',
            textTransform: 'none',
            fontWeight: '400',
          }}
          onClick={() => navigate('/requests')}>
          Volver
        </Button>
      </Grid>
      <MainTitle
        title={
          conciliationRequestId
            ? `Enmienda de solicitud ${conciliationRequestId}`
            : `Solicitud ${id}`
        }
        subtitle={`Estos son los datos que ingresaste en tu ${
          conciliationRequestId ? 'enmienda' : 'solicitud'
        }.`}
        status={
          <ColorCodedChip
            state={requestStatus?.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}
            sx={{ ml: 2 }}
          />
        }
      />
      <SectionTitle
        title="Datos del vehículo"
        sx={{
          mt: 4,
        }}
      />
      <Grid container item spacing={{ md: 5, xs: 2 }} sx={{ mb: 6 }}>
        <VehicleView
          vehicleVin={vehicleVin}
          vehicleLicensePlate={vehicleLicensePlate}
          vehicleBrand={vehicleBrand}
          vehicleModel={vehicleModel}
          vehicleYear={vehicleYear}
        />
      </Grid>
      <SectionTitle title="Datos del solicitante" />
      <Grid container item spacing={{ md: 5, xs: 2 }} sx={{ mb: 6 }}>
        <BeneficiaryView
          personAddress={personAddress}
          personCity={personCity}
          personCommune={personCommune}
          personEmail={personEmail}
          personMaternalSurname={personMaternalSurname}
          personNames={personNames}
          personPaternalSurname={personPaternalSurname}
          personPhone={personPhone}
          personRegion={personRegion}
          personTaxNumber={personTaxNumber}
        />
      </Grid>
      <SectionTitle title="Método de pago" />
      <Grid container item spacing={{ md: 5, xs: 2 }} sx={{ mb: 6 }}>
        <PaymentMethodView
          accountPaymentMethod={accountPaymentMethod}
          accountHolderName={accountHolderName}
          accountHolderTaxNumber={accountHolderTaxNumber}
          accountBank={accountBank}
          accountType={accountType}
          accountNumber={accountNumber}
        />
      </Grid>
      <SectionTitle title="Documentos adjuntos" />
      <PersonalDocumentsView
        frontIdCard={frontIdCard}
        backIdCard={backIdCard}
        carInscription={carInscription}
      />
    </Grid>
  );
}

export default BeneficiaryRequestDetail;
