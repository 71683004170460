import React from 'react';
import CustomTypographyView from 'content/shared/Views/CustomTypographyView';

function BeneficiaryView({
  personTaxNumber,
  personNames,
  personPaternalSurname,
  personMaternalSurname,
  personAddress,
  personRegion,
  personCity,
  personCommune,
  personPhone,
  personEmail,
}) {
  return (
    <>
      <CustomTypographyView label="RUT" text={personTaxNumber} />
      <CustomTypographyView label="Nombres" text={personNames} />
      <CustomTypographyView label="Apellido paterno" text={personPaternalSurname} />
      <CustomTypographyView label="Apellido materno" text={personMaternalSurname} />
      <CustomTypographyView label="Dirección" text={personAddress} />
      <CustomTypographyView label="Región" text={personRegion} />
      <CustomTypographyView label="Ciudad" text={personCity} />
      <CustomTypographyView label="Comuna" text={personCommune} />
      <CustomTypographyView label="Teléfono" text={personPhone} />
      <CustomTypographyView label="Correo electrónico" text={personEmail} />
    </>
  );
}

export default BeneficiaryView;
