import React, { useCallback } from 'react';

/* Material UI */
import { CardContent, CardMedia, Card, Grid } from '@mui/material';

/* Project */
import RegisterForm from 'content/features/authentication/register/components/RegisterForm';
import { setControl } from 'content/features/authentication/register/register.actions';
import { RegisterSelector } from 'content/features/authentication';

/* React Router */
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

/* Public */
const deloitteDesktop = `${window.location.origin}/images/deloitte-desktop-negro.png`;

const { page: registerSelector } = RegisterSelector;

function RegisterUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const states = useSelector(registerSelector);

  const { controls, loading } = states;

  const onChangeText = useCallback((name, value) => {
    dispatch(setControl({ name, value }));
  }, []);

  const goToHome = () => navigate('/');

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: '100vh', backgroundColor: '#E8EAE9' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card sx={{ boxShadow: 0, borderRadius: 6, maxWidth: '500px', maxHeight: '620px' }}>
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10} textAlign="center">
                  <CardMedia
                    onClick={goToHome}
                    component="img"
                    sx={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      maxWidth: '250px',
                      margin: 'auto',
                      mt: 4,
                    }}
                    image={deloitteDesktop}
                    title="Deloitte"
                  />
                </Grid>
              </Grid>
              <RegisterForm
                navigate={navigate}
                onChangeText={onChangeText}
                controls={controls}
                loading={loading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default RegisterUser;
