import { Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { ArrowBackIosNew } from '@mui/icons-material';
import BeneficiaryRequestDetailSkeleton from 'content/features/beneficiary-request-detail/components/BeneficiaryRequestDetailSkeleton';
import RequestStatusForm from 'content/features/operator-request-detail/components/RequestStatusForm';
import {
  acceptRequest,
  clearScreenData,
  fetchOperatorRequestDetail,
  objectRequest,
  payRequest,
  rejectRequest,
  setControl,
  toggleCheck,
} from 'content/features/operator-request-detail/operatorRequestDetail.actions';
import ColorCodedChip from 'content/shared/ColorCodedChip/ColorCodedChip';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import SectionTitle from 'content/shared/SectionTitle/SectionTitle';
import BeneficiaryView from 'content/shared/Views/BeneficiaryView';
import PaymentMethodView from 'content/shared/Views/PaymentMethodView';
import PersonalDocumentsView from 'content/shared/Views/PersonalDocumentsView';
import RequestStatusView from 'content/shared/Views/RequestStatusView';
import VehicleView from 'content/shared/Views/VehicleView';
import useForm from 'hooks/useForm';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { inputNoEmpty } from 'utils/helper';

// eslint-disable-next-line complexity
function OperatorRequestDetail() {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { id: requestId } = useParams();
  const { controls, loaders, request, initialData } = useSelector(
    (state) => state.operatorRequestDetail,
  );
  const { operatorUpdateRequestButtonId } = useSelector((state) => state.app.loaders);
  const { error, checkOptions, requestChanged } = controls;
  const {
    conciliationRequestId,
    vehicleLicensePlate,
    vehicleVin,
    vehicleBrand,
    vehicleModel,
    vehicleYear,
    personTaxNumber,
    personNames,
    personPaternalSurname,
    personMaternalSurname,
    personAddress,
    personRegion,
    personCity,
    personCommune,
    personPhone,
    personEmail,
    accountPaymentMethod,
    accountHolderName,
    accountHolderTaxNumber,
    accountBank,
    accountType,
    accountNumber,
    frontIdCard,
    backIdCard,
    carInscription,
    requestStatus,
    objectionReasonsSelected,
    objectionObservations,
  } = request;
  const { requestStatusValues, objectionReasons } = initialData;

  const requestStatusSelected = requestStatusValues.find(
    (rStatusValues) => rStatusValues.value === controls.requestStatusControl,
  );

  const requestStatusLabel = requestStatusValues.find(
    (rStatusValues) => rStatusValues.value === requestStatus,
  )?.label;

  console.log('REQUEST: ', requestStatusLabel);
  console.log('REQUEST VALUES: ', requestStatusValues);
  console.log('CONCILIATION: ', conciliationRequestId);
  console.log('OPERATOR BUTTON: ', operatorUpdateRequestButtonId);

  useEffect(() => {
    if (requestChanged) {
      navigate('/operator-requests');
    }
  }, [requestChanged]);

  useEffect(() => {
    dispatch(fetchOperatorRequestDetail({ id: requestId }));
    return () => {
      dispatch(clearScreenData());
    };
  }, [requestId]);

  useEffect(() => {
    if (error) {
      navigate('/operator-requests');
    }
  }, [error]);

  const onChangeText = useCallback(
    (id, value) => {
      const actions = {
        requestStatusControl: (val) => {
          dispatch(setControl({ name: 'requestStatusControlError', value: false }));
          dispatch(setControl({ name: 'checkError', value: false }));
          dispatch(setControl({ name: 'observations', value: '' }));
          const checkOptionsKeys = Object.keys(checkOptions);
          checkOptionsKeys.forEach((key) => dispatch(toggleCheck({ name: key, value: false })));
          return val;
        },
      };

      const action = actions[id];
      const newValue = action ? action(value) : value;

      dispatch(setControl({ name: id, value: newValue }));
    },
    [checkOptions],
  );

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      requestStatusControl: [
        {
          check: (value) => value !== requestStatus,
          message: 'Debe seleccionar un estado para la solicitud diferente del anterior',
        },
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un estado para la solicitud',
        },
      ],
      observations: [
        {
          check: () => true,
          message: 'Debe ingresar una observación',
        },
      ],
    },
  });
  const atLeastOneCheck = (checkValues) => Object.values(checkValues).some((value) => value);

  const onChangeCheckbox = (event) => {
    const { name, checked } = event.target;
    const { [name]: check, ...checksWithoutClickedCheckbox } = checkOptions;
    const checksVerification = atLeastOneCheck(checksWithoutClickedCheckbox);

    dispatch(setControl({ name: 'checkError', value: !(checksVerification || checked) }));

    dispatch(toggleCheck({ name, value: checked }));
  };

  // eslint-disable-next-line complexity
  const handleSubmit = (event) => {
    event.preventDefault();

    const actions = {
      OBJETADA: () => {
        const objectionReasonsFormatted = objectionReasons.map((objectionReason) => ({
          id: objectionReason.value,
          value: checkOptions[`reason${objectionReason.value}`] || false,
        }));

        const data = {
          objectionReasonsFormatted,
          observations: controls.observations,
          requestId,
        };

        dispatch(objectRequest(data));
      },
      ACEPTADA: () => {
        dispatch(acceptRequest(requestId));
      },
      RECHAZADA: () => {
        dispatch(rejectRequest(requestId));
      },
      PAGADA: () => {
        dispatch(payRequest(requestId));
      },
    };

    if (requestStatusSelected.label === 'OBJETADA' && !atLeastOneCheck(checkOptions)) {
      dispatch(setControl({ name: 'checkError', value: true }));
      return;
    }
    if (requestStatusLabel === requestStatusSelected.label) {
      dispatch(setControl({ name: 'requestStatusControlError', value: true }));
      return;
    }

    onSubmit(() => {
      actions[requestStatusSelected.label]?.();
    });
  };
  return loaders.initial ? (
    <BeneficiaryRequestDetailSkeleton />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ mt: 2.5 }}>
        <Button
          variant="text"
          startIcon={<ArrowBackIosNew color="primary" />}
          sx={{
            pl: 0,
            color: 'black',
            textTransform: 'none',
            fontWeight: '400',
          }}
          onClick={() => navigate('/operator-requests')}>
          Volver
        </Button>
      </Grid>
      <MainTitle
        title={
          conciliationRequestId
            ? `Enmienda de solicitud ${conciliationRequestId}`
            : `Solicitud ${requestId}`
        }
        subtitle={`Recuerda que luego de revisar los datos ingresados deberás cambiar el estado de la ${
          conciliationRequestId ? 'enmienda' : 'solicitud'
        }.`}
        status={
          <ColorCodedChip
            state={requestStatusLabel?.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())}
            sx={{ ml: 2 }}
          />
        }
        icon={undefined}
      />
      <SectionTitle title="Datos del vehículo" sx={undefined} />
      <Grid container item spacing={{ md: 5, xs: 2 }} sx={{ mb: 6 }}>
        <VehicleView
          vehicleLicensePlate={vehicleLicensePlate}
          vehicleVin={vehicleVin}
          vehicleYear={vehicleYear}
          vehicleBrand={vehicleBrand}
          vehicleModel={vehicleModel}
        />
      </Grid>
      <SectionTitle title="Datos del solicitante" sx={undefined} />
      <Grid container item spacing={{ md: 5, xs: 2 }} sx={{ mb: 6 }}>
        <BeneficiaryView
          personAddress={personAddress}
          personCity={personCity}
          personCommune={personCommune}
          personEmail={personEmail}
          personMaternalSurname={personMaternalSurname}
          personNames={personNames}
          personPaternalSurname={personPaternalSurname}
          personPhone={personPhone}
          personRegion={personRegion}
          personTaxNumber={personTaxNumber}
        />
      </Grid>
      <SectionTitle title="Método de pago" sx={undefined} />
      <Grid container item spacing={{ md: 5, xs: 2 }} sx={{ mb: 6 }}>
        <PaymentMethodView
          accountPaymentMethod={accountPaymentMethod}
          accountHolderName={accountHolderName}
          accountHolderTaxNumber={accountHolderTaxNumber}
          accountBank={accountBank}
          accountType={accountType}
          accountNumber={accountNumber}
        />
      </Grid>
      <SectionTitle title="Documentos adjuntos" sx={undefined} />
      <PersonalDocumentsView
        frontIdCard={frontIdCard}
        backIdCard={backIdCard}
        carInscription={carInscription}
      />
      {requestStatusLabel === 'OBJETADA' && (
        <>
          <SectionTitle title="Observaciones" sx={undefined} />
          <RequestStatusView
            objectionReasonsSelected={objectionReasonsSelected}
            objectionObservations={objectionObservations}
          />
        </>
      )}
      {(conciliationRequestId && requestStatusLabel === 'INGRESADA') ||
      (!conciliationRequestId &&
        requestStatusLabel !== 'PAGADA' &&
        requestStatusLabel !== 'OBJETADA') ||
      (conciliationRequestId && requestStatusLabel === 'ACEPTADA') ? (
        <>
          <SectionTitle title="Confirmación de solicitud" sx={undefined} />
          <RequestStatusForm
            onChange={onChange}
            errors={errors}
            onChangeCheckbox={onChangeCheckbox}
          />
        </>
      ) : null}

      <Grid
        container
        item
        direction={{ md: 'row', xs: 'column-reverse' }}
        spacing={{ md: 4, xs: 2 }}>
        <Grid container item md={6} justifyContent={{ md: 'flex-start', xs: 'center' }}>
          <Button
            variant="outlined"
            size="large"
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              color: 'black',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'black',
            }}
            fullWidth={isMobile}
            onClick={() => navigate('/operator-requests')}>
            Volver
          </Button>
        </Grid>
        <Grid container item md={6} justifyContent={{ md: 'flex-end', xs: 'center' }}>
          <Button
            id="operatorUpdateRequestButtonId"
            variant="contained"
            size="large"
            fullWidth={isMobile}
            onClick={handleSubmit}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            disabled={
              requestStatusLabel === 'OBJETADA' ||
              operatorUpdateRequestButtonId ||
              (requestStatusLabel !== 'INGRESADA' && conciliationRequestId) ||
              false
            }
            startIcon={operatorUpdateRequestButtonId ? <CircularProgress size={15} /> : null}>
            Cambiar estado de solicitud
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OperatorRequestDetail;
