import React from 'react';
import { Checkbox, Grid, Typography } from '@mui/material';
import { borderColorFunction } from 'utils/helper';

function TermsAndConditions({ errors, onChange, termsAndConditions }) {
  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        mt: { md: 4, xs: 6 },
      }}>
      <Grid item md={6} xs={12}>
        <Typography
          variant="body2"
          fontWeight="300"
          sx={{
            color: borderColorFunction(errors.termsAndConditions, '#12266F'),
            display: 'flex',
            alignItems: 'center',
          }}>
          <Checkbox
            sx={{
              pl: 0,
            }}
            checked={termsAndConditions}
            onChange={onChange}
            name="termsAndConditions"
            id="termsAndConditions"
          />
          Leí y acepto las declaraciones correspondientes
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TermsAndConditions;
