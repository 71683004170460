const navbarStyles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: (theme) => theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  printStatus: {
    marginRight: (theme) => theme.spacing(2),
    fontFamily: 'Roboto',
  },
  navBar: {
    backgroundColor: 'black',
    boxShadow: '2',
  },
  userWelcome: {
    color: 'white',
    fontSize: 12,
  },
  divider: {
    backgroundColor: 'white',
    marginLeft: 2,
    marginRight: 2,
  },
};

export default navbarStyles;
