import { createReducer } from '@reduxjs/toolkit';
import OperatorRequestsTypes from 'content/features/operator-requests/operatorRequests.types';

const initialState = {
  loaders: {
    initial: false,
    reveal: false,
    hide: false,
    assign: false,
    filters: false,
  },
  controls: {
    error: false,
    individualChecks: {},
    confirmationModal: false,
    checksError: false,
    requestId: '',
    startDate: null,
    endDate: null,
    rut: '',
    licensePlate: '',
    states: '',
    operator: '',
    reportsMenu: false,
    anchorElMenu: null,
    reportsError: false,
  },
  pagination: {
    page: 0,
    pageSize: 10,
    totalRequests: 0,
  },
  initialData: {
    operators: [],
    statesValues: [],
  },
  requests: [],
};

const OperatorRequestsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(OperatorRequestsTypes.FETCH_REQUESTS, (state) => {
      state.loaders.initial = true;
      state.controls.error = false;
    })
    .addCase(OperatorRequestsTypes.FETCH_REQUESTS_FAIL, (state) => {
      state.loaders.initial = false;
      state.controls.error = true;
    })
    .addCase(OperatorRequestsTypes.FETCH_REQUESTS_SUCCESS, (state, action) => {
      state.loaders.initial = false;
      state.requests = action.payload.requests;
      state.pagination.totalRequests = action.payload.totalRequests;
    })
    .addCase(OperatorRequestsTypes.SET_PAGINATION, (state, action) => {
      state.pagination[action.payload.name] = action.payload.value;
    })
    .addCase(OperatorRequestsTypes.REVEAL_ROW_DATA, (state) => {
      state.loaders.reveal = true;
    })
    .addCase(OperatorRequestsTypes.REVEAL_ROW_DATA_FAIL, (state) => {
      state.loaders.reveal = false;
    })
    .addCase(OperatorRequestsTypes.REVEAL_ROW_DATA_SUCCESS, (state, action) => {
      state.loaders.reveal = false;
      state.requests = state.requests.map((request) => {
        if (request.id === action.payload.id) {
          return { ...action.payload };
        }
        return request;
      });
    })
    .addCase(OperatorRequestsTypes.SET_CONTROL, (state, action) => {
      state.controls[action.payload.name] = action.payload.value;
    })
    .addCase(OperatorRequestsTypes.CREATE_REQUEST_CHECKBOXES_CONTROLS, (state, action) => {
      action.payload.forEach((row) => {
        state.controls.individualChecks[row.id] = false;
      });
    })
    .addCase(OperatorRequestsTypes.TOGGLE_INDIVIDUAL_CHECK, (state, action) => {
      state.controls.individualChecks[action.payload.name] = action.payload.value;
    })
    .addCase(OperatorRequestsTypes.TOGGLE_MASTER_CHECK, (state, actions) => {
      Object.keys(state.controls.individualChecks).forEach((key) => {
        state.controls.individualChecks[key] = actions.payload;
      });
    })
    .addCase(OperatorRequestsTypes.HIDE_ROW_DATA, (state) => {
      state.loaders.hide = true;
    })
    .addCase(OperatorRequestsTypes.HIDE_ROW_DATA_FAIL, (state) => {
      state.loaders.hide = false;
    })
    .addCase(OperatorRequestsTypes.HIDE_ROW_DATA_SUCCESS, (state, action) => {
      state.loaders.hide = false;
      state.requests = state.requests.map((request) => {
        if (request.id === action.payload.id) {
          return { ...action.payload };
        }
        return request;
      });
    })
    .addCase(OperatorRequestsTypes.ASSIGN_REQUEST, (state) => {
      state.loaders.assign = true;
    })
    .addCase(OperatorRequestsTypes.ASSIGN_REQUEST_FAIL, (state) => {
      state.loaders.assign = false;
    })
    .addCase(OperatorRequestsTypes.ASSIGN_REQUEST_SUCCESS, (state) => {
      state.loaders.assign = false;
      state.controls.confirmationModal = false;
      Object.keys(state.controls.individualChecks).forEach((key) => {
        state.controls.individualChecks[key] = false;
      });
    })
    .addCase(OperatorRequestsTypes.GET_INITIAL_DATA, (state) => {
      state.loaders.filters = true;
    })
    .addCase(OperatorRequestsTypes.GET_INITIAL_DATA_FAIL, (state) => {
      state.loaders.filters = false;
    })
    .addCase(OperatorRequestsTypes.GET_INITIAL_DATA_SUCCESS, (state, action) => {
      state.loaders.filters = false;
      state.initialData = action.payload;
    })
    .addCase(OperatorRequestsTypes.CLEAR_FILTERS, (state) => {
      state.controls = {
        ...state.controls,
        requestId: '',
        startDate: null,
        endDate: null,
        rut: '',
        licensePlate: '',
        states: '',
        operator: '',
      };
    })
    .addCase(OperatorRequestsTypes.GET_CLIENT_REPORT, (state) => {
      state.controls.reportsError = false;
    })
    .addCase(OperatorRequestsTypes.GET_CLIENT_REPORT_FAIL, (state) => {
      state.controls.reportsError = true;
    })
    .addCase(OperatorRequestsTypes.GET_CLIENT_REPORT_SUCCESS, (state) => {
      state.controls.reportsError = false;
    })
    .addCase(OperatorRequestsTypes.GET_TABLE_REPORT, (state) => {
      state.controls.reportsError = false;
    })
    .addCase(OperatorRequestsTypes.GET_TABLE_REPORT_FAIL, (state) => {
      state.controls.reportsError = true;
    })
    .addCase(OperatorRequestsTypes.GET_TABLE_REPORT_SUCCESS, (state) => {
      state.controls.reportsError = false;
    });
});

export default OperatorRequestsReducer;
