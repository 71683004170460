import React, { useCallback, useEffect } from 'react';
import { Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  dropZoneNoEmpty,
  formatLettersAndSpaces,
  formatOnlyNumbers,
  formatRut,
  inputLettersAndSpaces,
  inputNoEmpty,
  inputNumberAndLetters,
  inputOnlyNumbers,
  validateEmail,
  validateLicensePlate,
  validatePhoneNumbers,
  validateRut,
} from 'utils/helper';
import useForm from 'hooks/useForm';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import SectionTitle from 'content/shared/SectionTitle/SectionTitle';
import VehicleForm from 'content/features/beneficiary-request/components/VehicleForm';
import BeneficiaryForm from 'content/features/beneficiary-request/components/BeneficiaryForm';
import PaymentMethodForm from 'content/features/beneficiary-request/components/PaymentMethodForm';
import PersonalDocumentsForm from 'content/features/beneficiary-request/components/PersonalDocumentsForm';
import TermsAndConditions from 'content/features/beneficiary-request/components/TermsAndConditions';
import {
  clearScreenData,
  createBeneficiaryAmendment,
  fetchFormInitialData,
  fetchRequest,
  // fetchRequest,
  setControl,
} from 'content/features/beneficiary-amendment/beneficiaryAmendment.actions';
import RequestStatusView from 'content/shared/Views/RequestStatusView';
import BeneficiaryRequestDetailSkeleton from 'content/features/beneficiary-request-detail/components/BeneficiaryRequestDetailSkeleton';

// eslint-disable-next-line complexity
function BeneficiaryAmendment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: requestId } = useParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { controls, loaders, initialData, errorControls } = useSelector(
    (state) => state.beneficiaryAmendment,
  );
  const { createBeneficiaryAmendmentButtonId } = useSelector((state) => state.app.loaders);
  const { accountPaymentMethods } = initialData;
  const {
    rut,
    accountPaymentMethod,
    termsAndConditions,
    objectionReasonsSelected,
    objectionObservations,
  } = controls;
  const { initialError } = errorControls;

  console.log('controls', controls);

  const transferId = accountPaymentMethods.find(
    (method) => method.label === 'TRANSFERENCIA',
  )?.value;

  useEffect(() => {
    dispatch(fetchRequest({ id: requestId }));
  }, [requestId]);

  useEffect(() => {
    if (initialError) {
      navigate('/requests');
    }
  }, [initialError]);

  useEffect(() => {
    dispatch(fetchFormInitialData());

    return () => {
      dispatch(clearScreenData());
    };
  }, []);

  useEffect(() => {
    if (loaders.amendmentCreated) navigate('/requests');
  }, [loaders.amendmentCreated]);

  const onChangeText = useCallback((id, value) => {
    const actions = {
      rut: (val) => formatRut(val),
      accountHolderRut: (val) => formatRut(val),
      licensePlate: (val) => val.toUpperCase(),
      vin: (val) => val.toUpperCase(),
      brand: (val) => {
        dispatch(setControl({ name: 'model', value: '' }));
        dispatch(setControl({ name: 'year', value: '' }));
        return val;
      },
      model: (val) => {
        dispatch(setControl({ name: 'year', value: '' }));
        return val;
      },
      names: (val) => formatLettersAndSpaces(val),
      paternalSurname: (val) => formatLettersAndSpaces(val),
      maternalSurname: (val) => formatLettersAndSpaces(val),
      accountHolderName: (val) => formatLettersAndSpaces(val),
      accountHolderNumber: (val) => formatOnlyNumbers(val),
      phone: (val) => formatOnlyNumbers(val),
      region: (val) => {
        dispatch(setControl({ name: 'city', value: '' }));
        dispatch(setControl({ name: 'commune', value: '' }));
        return val;
      },
      city: (val) => {
        dispatch(setControl({ name: 'commune', value: '' }));
        return val;
      },
      accountPaymentMethod: (val) => {
        dispatch(setControl({ name: 'accountBank', value: '' }));
        dispatch(setControl({ name: 'accountType', value: '' }));
        dispatch(setControl({ name: 'accountHolderNumber', value: '' }));
        return val;
      },
    };

    const action = actions[id];
    const newValue = action ? action(value) : value;

    dispatch(setControl({ name: id, value: newValue }));
  }, []);

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      vin: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un VIN',
        },
        {
          check: inputNumberAndLetters,
          message: 'El VIN ingresado no es válido',
        },
        {
          check: (value) => value.length === 17,
          message: 'El VIN ingresado no es válido',
        },
      ],
      licensePlate: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar una patente',
        },
        {
          check: validateLicensePlate,
          message: 'La patente ingresada no es válida',
        },
        {
          check: (value) => value.length === 6,
          message: 'La patente ingresada no es válida',
        },
      ],
      brand: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una marca',
        },
      ],
      model: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un modelo',
        },
      ],
      year: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un año',
        },
      ],
      rut: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un RUT',
        },
        {
          check: validateRut,
          message: 'El RUT ingresado no es válido',
        },
      ],
      names: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un nombre',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El nombre ingresado no es válido',
        },
      ],
      paternalSurname: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un apellido paterno',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El apellido ingresado no es válido',
        },
      ],
      maternalSurname: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un apellido materno',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El apellido ingresado no es válido',
        },
      ],
      address: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar una dirección',
        },
      ],
      region: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una región',
        },
      ],
      city: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una ciudad',
        },
      ],
      commune: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una comuna',
        },
      ],
      phone: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un teléfono',
        },
        {
          check: inputOnlyNumbers,
          message: 'El teléfono ingresado no es válido',
        },
        {
          check: validatePhoneNumbers,
          message: 'El teléfono ingresado no es válido',
        },
      ],
      email: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un correo',
        },
        {
          check: validateEmail,
          message: 'El correo ingresado no es válido',
        },
      ],
      accountPaymentMethod: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un método de pago',
        },
      ],
      accountHolderName: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un nombre',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El nombre ingresado no es válido',
        },
      ],
      accountHolderRut: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un RUT',
        },
        {
          check: validateRut,
          message: 'El RUT ingresado no es válido',
        },
        {
          check: (value) => value === rut,
          message: 'El RUT ingresado DEBE ser igual al RUT solicitante',
        },
      ],
      accountBank: [
        {
          check: (value) => inputNoEmpty(value) || transferId !== accountPaymentMethod,
          message: 'Debe seleccionar un banco',
        },
      ],
      accountType: [
        {
          check: (value) => inputNoEmpty(value) || transferId !== accountPaymentMethod,
          message: 'Debe seleccionar un tipo de cuenta',
        },
      ],
      accountHolderNumber: [
        {
          check: (value) => inputNoEmpty(value) || transferId !== accountPaymentMethod,
          message: 'Debe ingresar un número de cuenta',
        },
        {
          check: (value) => inputOnlyNumbers(value) || transferId !== accountPaymentMethod,
          message: 'El número de cuenta ingresado no es válido',
        },
      ],
      frontIdentityDocument: [
        {
          check: dropZoneNoEmpty,
          message: 'Debe adjuntar un archivo',
        },
      ],
      backIdentityDocument: [
        {
          check: dropZoneNoEmpty,
          message: 'Debe adjuntar un archivo',
        },
      ],
      carInscriptionDocument: [
        {
          check: dropZoneNoEmpty,
          message: 'Debe adjuntar un archivo',
        },
      ],
      termsAndConditions: [
        {
          check: (value) => value,
          message: 'Debe aceptar los términos y condiciones',
        },
      ],
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(() => {
      dispatch(createBeneficiaryAmendment({ ...controls, id: requestId }));
    });
  };

  return loaders.preLoadedData ? (
    <BeneficiaryRequestDetailSkeleton />
  ) : (
    <Grid container spacing={2}>
      <MainTitle
        title="Enmendar solicitud"
        subtitle="Revisa las observaciones hechas y corrige los datos. Ten en cuenta que la enmienda solo se podrá realizar una vez."
      />
      <SectionTitle
        title="Observaciones"
        sx={{
          mt: 4,
        }}
      />
      <RequestStatusView
        objectionReasonsSelected={objectionReasonsSelected}
        objectionObservations={objectionObservations}
      />
      <SectionTitle
        title="Datos del vehículo"
        sx={{
          mt: 4,
        }}
      />
      <Grid container item spacing={{ md: 4, xs: 2 }} sx={{ mb: 6 }}>
        <VehicleForm
          errors={errors}
          onChange={onChange}
          controls={controls}
          initialData={initialData}
          amendment
        />
      </Grid>
      <SectionTitle title="Datos del solicitante" />
      <Grid container item spacing={{ md: 4, xs: 2 }} sx={{ mb: 6 }}>
        <BeneficiaryForm
          onChange={onChange}
          errors={errors}
          controls={controls}
          initialData={initialData}
          amendment
        />
      </Grid>
      <SectionTitle title="Método de pago" />
      <Grid container item spacing={{ md: 4, xs: 2 }} sx={{ mb: 6 }}>
        <PaymentMethodForm
          onChange={onChange}
          errors={errors}
          controls={controls}
          initialData={initialData}
          amendment
        />
      </Grid>
      <SectionTitle title="Documentos adjuntos" />
      <PersonalDocumentsForm onChange={onChange} errors={errors} controls={controls} />
      <TermsAndConditions
        onChange={onChange}
        errors={errors}
        termsAndConditions={termsAndConditions}
      />
      <Grid
        container
        item
        direction={{ md: 'row', xs: 'column-reverse' }}
        spacing={{ md: 4, xs: 2 }}>
        <Grid container item md={6} justifyContent={{ md: 'flex-start', xs: 'center' }}>
          <Button
            variant="outlined"
            size="large"
            fullWidth={isMobile}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={() => navigate('/requests')}>
            Cancelar enmienda
          </Button>
        </Grid>
        <Grid container item md={6} justifyContent={{ md: 'flex-end', xs: 'center' }}>
          <Button
            id="createBeneficiaryAmendmentButtonId"
            variant="contained"
            size="large"
            fullWidth={isMobile}
            onClick={handleSubmit}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            disabled={createBeneficiaryAmendmentButtonId || false}
            startIcon={createBeneficiaryAmendmentButtonId ? <CircularProgress size={15} /> : null}>
            Ingresar enmienda
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BeneficiaryAmendment;
