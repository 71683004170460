import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { Typography } from '@mui/material';
import React from 'react';
import { getItemInStorage } from 'utils/functions';

function StyledTableCell({ text, sx = {}, align = 'left' }) {
  return (
    <TableCell
      align={align}
      sx={{
        backgroundColor: 'black',
        color: 'white',
        pt: 2,
        pb: 2,
        fontWeight: 'semibold',
        ...sx,
      }}>
      {text}
    </TableCell>
  );
}

function UserTable({ rows }) {
  const user = getItemInStorage('user');
  console.log('user', user);
  return (
    <TableContainer>
      <Table aria-label="request table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ borderTopLeftRadius: 8, pr: 0 }} text="ID de usuario" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Nombre" />
            <StyledTableCell align="center" sx={{ borderTopRightRadius: 8, pl: 0 }} text="Email" />
            {user.userType === 'admin' && (
              <StyledTableCell align="center" sx={{ borderTopRightRadius: 8, pl: 0 }} text="Rol" />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  No hay usuarios
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {rows?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}>
              <TableCell align="left" sx={{ pr: 0, pt: 3, pb: 3 }}>
                {row.id}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.name}
              </TableCell>
              <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                {row.email}
              </TableCell>
              {user.userType === 'admin' && (
                <TableCell align="center" sx={{ pl: 0, pt: 3, pb: 3 }}>
                  {row.type}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserTable;
