import React from 'react';

import { Grid } from '@mui/material';
import { DescriptionRounded } from '@mui/icons-material';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import Document from 'content/features/terms-and-conditions/components/Document';
import {
  document1,
  document2,
  document3,
} from '../../features/terms-and-conditions/components/termsAndConditionsData';

function TermsAndConditions() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MainTitle
          title="Términos y condiciones"
          subtitle="Revisa aquí los términos y condiciones de nuestro sistema de conciliación."
          icon={<DescriptionRounded sx={{ fontSize: 40, marginRight: 1, color: '#86BC25' }} />}
        />
        <Document data={document1} />
        <Document data={document2} />
        <Document data={document3} />
      </Grid>
    </Grid>
  );
}

export default TermsAndConditions;
