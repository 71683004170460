import React from 'react';

// Material
import Grid from '@mui/material/Grid';

/* Project */

function Footer() {
  return (
    <div className="footer" style={{ minHeight: '7vh' }}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item />
      </Grid>
    </div>
  );
}

export default Footer;
