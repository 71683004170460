const UserMainterTypes = {
  FETCH_USERS: '[UserMaintainer] FETCH_USERS',
  FETCH_USERS_SUCCESS: '[UserMaintainer] FETCH_USERS_SUCCESS',
  FETCH_USERS_FAIL: '[UserMaintainer] FETCH_USERS_FAIL',
  SET_PAGINATION: '[UserMaintainer] SET_PAGINATION',
  SET_CONTROL: '[UserMaintainer] SET_CONTROL',
  CLEAR_FILTERS: '[UserMaintainer] CLEAR_FILTERS',
};

export default UserMainterTypes;
