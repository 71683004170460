import { Environment } from 'model-environment';
import { parse, isValid, isAfter } from 'date-fns';
import Models from '../models';

const deconsructRUT = (prevRUT) => {
  let M = 0;
  let S = 1;
  let rut = prevRUT;

  for (; rut; rut = Math.floor(rut / 10)) S = (S + (rut % 10) * (9 - (M += 1 % 6))) % 11;

  return {
    M,
    S,
  };
};

const verifierDigit = (rut) => {
  const { S } = deconsructRUT(rut);
  return S ? S - 1 : 'k';
};

const completeRUT = (rut) => rut.toString()?.replace(/[-.]/g, '').trim();

const testRut = (rut) => {
  const fullRUT = completeRUT(rut);
  const match = /^[0-9]+[0-9kK]{1}$/.test(fullRUT);

  let digv = fullRUT.slice(-1);
  const rutSinDv = fullRUT.slice(0, -1);

  if (digv === 'K') digv = 'k';

  return {
    match,
    fullRUT,
    digv,
    rutSinDv,
  };
};

export const validateTaxNumber = (rut = '') => {
  // if (!rut) return false;

  const { match, rutSinDv, digv } = testRut(rut);

  if (!match) return false;

  return verifierDigit(rutSinDv).toString() === digv.toString();
};

export const onlyKeysWithValue = (obj) => {
  const result = {};

  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      result[key] = obj[key];
    }
  });
  return result;
};

export const getExtension = (fileName) => fileName.split('.').pop().toLowerCase();

export const removeExtension = (fileName) =>
  fileName.split('.').slice(0, -1).join('.').toLowerCase();

/**
 * @param {Object} objects
 * @returns {Object}
 */
export const reselectObjects = (objects) => new Environment({ objects }, Models).parseDB().objects;

export const normalizeObj = (arr) =>
  arr.reduce((prev, current) => {
    // eslint-disable-next-line no-param-reassign
    prev[current.id] = current;
    return prev;
  }, {});

const converToYoutubeUrl = ({ input, pattern }) => {
  const replacement = 'https://www.youtube.com/embed/$1';

  if (input.includes('/embed')) return input;

  return input.replace(pattern, replacement);
};

const convertToVimeoUrl = ({ input, pattern }) => {
  const replacement = 'https://player.vimeo.com/video/$1';

  if (input.includes('https://player')) return input;

  return input.replace(pattern, replacement);
};

const matchYoutubePattern = ({ input }) => {
  const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
  if (pattern.test(input)) {
    return converToYoutubeUrl({ input, pattern });
  }

  return null;
};

const matchVimeoPattern = ({ input }) => {
  const pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(\S+)/g;
  if (pattern.test(input)) {
    return convertToVimeoUrl({ input, pattern });
  }

  return null;
};

const switchYoutubeToVimeo = ({ isYoutube, input }) => {
  if (isYoutube) {
    return matchYoutubePattern({ input });
  }

  return matchVimeoPattern({ input });
};

const validateInput = (input) => {
  const youtubePrefix = ['youtube', 'youtu', 'youtube.com', 'youtu.be'];
  // const isYoutube = input.includes('youtube') || input.includes('youtu.be');
  const isYoutube = youtubePrefix.some((prefix) => input.includes(prefix));
  const isVimeo = input.includes('vimeo');

  return {
    isYoutube,
    isVimeo,
    noMatch: !isYoutube && !isVimeo,
  };
};

export const convertVideo = (input) => {
  const validation = validateInput(input);
  const { noMatch, isYoutube } = validation;

  if (noMatch) return null;

  return switchYoutubeToVimeo({ isYoutube, input });
};

export const captureEnterEvent = (evt, callback) => {
  if (evt.key === 'Enter') {
    callback();
  }
};

const paramRegexResult = (url, param) => {
  const regex = new RegExp(`[?&]${param}(=([^&#]*)|&|#|$)`);
  const result = regex.exec(url);

  return result ? result[2] : null;
};

export const getParameterByName = (param = '', url = window.location.search) => {
  const path = paramRegexResult(url, param);

  if (path) {
    return decodeURIComponent(path.replace(/\+/g, ' '));
  }

  return '';
};

const validateEntry = ({ entries, more }) => entries[0].isIntersecting && more;

const disconectObserver = ({ observer }) => {
  // Disconnect observer set on previous last element
  if (observer.current) observer.current.disconnect();
};

const connectObserver = ({ observer, element }) => {
  // observe/monitor last element
  if (element) observer.current.observe(element);
};

// TODO: Refactor for multiple usage
export const handleObserver = ({ element, observer, more, setPage }) => {
  // Element is the React element being referenced

  disconectObserver({ observer });

  // if there's no more data to be fetched, don't set new observer
  if (!more) return;

  // Set new observer
  observer.current = new IntersectionObserver((entries) => {
    // increase page number when element enters (is intersecting with) viewport.
    // This triggers the pagination hook to fetch more items in the new page
    if (validateEntry({ entries, more })) setPage((prev) => prev + 1);
  });

  connectObserver({ observer, element });
};

export const borderColorFunction = (error, baseColor) => (error?.length ? '#DA291C' : baseColor);

export const inputNoEmpty = (value) => value !== '' && value.toString().trim().length > 0;
export const dropZoneNoEmpty = (value) => value !== null && value !== '';
export const inputNumberAndLetters = (value) => /^[0-9A-Za-z]+$/.test(value);

export const inputLength = (value) => value.toString().trim().length <= 255;

export const formatOnlyNumbers = (value) => value.replace(/[^0-9]/g, '');
export const inputOnlyNumbers = (value) => {
  const formattedValue = formatOnlyNumbers(value);
  return /^[0-9]+$/.test(formattedValue);
};

export const validatePhoneNumbers = (value) => {
  const formattedValue = formatOnlyNumbers(value);
  return /^(?:56)?(?:9\d{8}|(?:2|32|33|34|35|41|42|43|44|45|51|52|53|55|57|58|61|63|64|65|67|71|72|73|75)\d{7})$/.test(
    formattedValue,
  );
};

export const validateDate = (value) => {
  const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
  if (!isValid(parsedDate)) return false;

  const minDate = new Date(1900, 0, 1); // 01-01-1900
  return isAfter(parsedDate, minDate);
};

export const validateDateRange = (start, end) =>
  isAfter(parse(start, 'yyyy-MM-dd', new Date()), parse(end, 'yyyy-MM-dd', new Date()));

export const formatLettersAndSpaces = (value) => value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g, '');
export const inputLettersAndSpaces = (value) => {
  const formattedValue = formatLettersAndSpaces(value);
  return /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/.test(formattedValue);
};

export const validateLicensePlate = (value) =>
  /^(?:[A-Za-z]{2}\d{4}|[A-Za-z]{4}\d{2})$/.test(value.toUpperCase());
export const validateEmail = (value) =>
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
    value,
  );

export const getExpectedVerificationDigit = (numbers) => {
  // Se invierte la cadena para simplificar los cálculos
  const reversedNumbers = numbers.split('').reverse().map(Number);

  const sum = reversedNumbers.reduce((acc, digit, index) => {
    // El multiplicador alterna entre 2 y 7
    const multiplier = (index % 6) + 2;
    return acc + digit * multiplier;
  }, 0);

  // Se obtiene el residuo de la suma dividida por 11
  const remainder = sum % 11;

  // Se calcula el dígito verificador
  let verificationDigit;
  if (remainder === 0) {
    verificationDigit = '0';
  } else if (remainder === 1) {
    verificationDigit = 'K';
  } else {
    verificationDigit = String(11 - remainder);
  }

  return verificationDigit;
};

export const formatRut = (rut) => {
  if (!rut) return '';
  if (rut.length > 12) return rut.substring(0, 12);
  // Elimina cualquier caracter que no sea número ni la "k" o "K"
  const rutValue = rut.replace(/[^\dkK]/g, '');

  // Divide el RUT en la parte numérica y el dígito verificador
  const rutPart = rutValue.slice(0, -1).replace(/\./g, '');
  const dv = rutValue.slice(-1).toUpperCase();

  // Formatea la parte numérica del RUT
  const rutFormatted = rutPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

  // Devuelve el RUT formateado junto con el dígito verificador
  return `${rutFormatted}-${dv}`;
};

export const validateRut = (rut) => {
  const formatedRut = formatRut(rut);
  const rutPattern = /^\d{1,3}\.?\d{3}\.?\d{3}-(\d|K)$/i;
  if (!rutPattern.test(formatedRut)) {
    return false;
  }

  const rutNumbers = formatedRut.replace(/\./g, '').split('-')[0];
  const verificationDigit = formatedRut.split('-')[1].toUpperCase();
  const expectedVd = getExpectedVerificationDigit(rutNumbers);

  return expectedVd === verificationDigit;
};

export const getExtensionFromContentType = (contentType) => {
  const contentTypeExtensionMap = {
    'text/plain': 'txt',
    'text/html': 'html',
    'text/css': 'css',
    'application/javascript': 'js',
    'application/json': 'json',
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'application/pdf': 'pdf',
  };

  return contentTypeExtensionMap[contentType] || '';
};
