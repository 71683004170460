import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Button, Grid, Typography, Card } from '@mui/material';

function SentryFallback(props) {
  const { error, componentStack, resetError } = props;
  // eslint-disable-next-line no-console
  console.log({ componentStack });

  const handleClick = () => {
    resetError();
    // eslint-disable-next-line no-console
    console.clear();
  };

  return (
    <Grid container sx={{ margin: '2rem', width: '90vw' }} spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">You have encountered an error</Typography>
      </Grid>

      <Grid item xs={12}>
        <Card sx={{ padding: '1rem' }}>
          <code>{error.toString()}</code>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="info"
          onClick={handleClick}
          sx={{ textTransform: 'none', fontWeight: 'bold' }}>
          Click here to return to the app
        </Button>
      </Grid>
    </Grid>
  );
}

SentryFallback.propTypes = {
  error: PropTypes.shape({}).isRequired,
  componentStack: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
};

export default SentryFallback;
