import { createAction } from '@reduxjs/toolkit';

import UserMaintainerTypes from './userMaintainer.types';

export const fetchUserList = createAction(UserMaintainerTypes.FETCH_USERS);

export const fetchUserListFail = createAction(UserMaintainerTypes.FETCH_USERS_FAIL);

export const fetchUserListSuccess = createAction(UserMaintainerTypes.FETCH_USERS_SUCCESS);

export const setPagination = createAction(UserMaintainerTypes.SET_PAGINATION, (payload) => ({
  payload,
}));

export const setControl = createAction(UserMaintainerTypes.SET_CONTROL, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const clearFilters = createAction(UserMaintainerTypes.CLEAR_FILTERS);

const UserMaintainerActions = {
  fetchUserList,
  fetchUserListFail,
  fetchUserListSuccess,
  setPagination,
  setControl,
  clearFilters,
};

export default UserMaintainerActions;
