import { spawn, takeLatest, put, call } from 'redux-saga/effects';

import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import apiRequest from 'utils/api';
import { setLoader } from 'content/app.actions';
import { getItemInStorage } from 'utils/functions';
import {
  fetchOperatorRequestsList,
  fetchOperatorRequestsListFail,
  fetchOperatorRequestsListSuccess,
  revealRowData,
  revealRowDataFail,
  revealRowDataSuccess,
  hideRowData,
  hideRowDataFail,
  hideRowDataSuccess,
  assignRequest,
  assignRequestSuccess,
  assignRequestFail,
  getInitialData,
  getInitialDataFail,
  getInitialDataSuccess,
  getTableReportSuccess,
  getTableReportFail,
  getTableReport,
  getClientReport,
} from './operatorRequests.actions';

function craftParams(controls, pagination) {
  const { page, pageSize } = pagination;
  const { requestId, startDate, endDate, licensePlate, states, operator, rut } = controls;
  return `api/v1/conciliation-request/operator/requests?page=${page}&pageSize=${pageSize}&requestId=${requestId}&startDate=${startDate}&endDate=${endDate}&licensePlate=${licensePlate}&states=${states}&operator=${operator}&rut=${rut}`;
}

function craftReportParams(controls) {
  const { requestId, startDate, endDate, licensePlate, states, operator, rut } = controls;
  return `api/v1/conciliation-request/operator/download-report-requests?requestId=${requestId}&startDate=${startDate}&endDate=${endDate}&licensePlate=${licensePlate}&states=${states}&operator=${operator}&rut=${rut}`;
}

function* list() {
  yield takeLatest(fetchOperatorRequestsList, function* fetchOperatorRequestsListSaga(action) {
    const { controls, pagination } = action.payload;

    const response = yield apiRequest(craftParams(controls, pagination), {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchOperatorRequestsListFail());
    } else {
      defaultSuccessToast({ message: 'Solicitudes cargadas exitosamente.' });
      yield put(fetchOperatorRequestsListSuccess(response));
    }
  });
}

function* reveal() {
  yield takeLatest(revealRowData, function* revealRowDataSaga(action) {
    const { id } = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/reveal-row/${id}`, {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(revealRowDataFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud revelada exitosamente.' });
      yield put(revealRowDataSuccess(response));
    }
  });
}

function* hide() {
  yield takeLatest(hideRowData, function* hideRowDataSaga(action) {
    const { id } = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/hide-row/${id}`, {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(hideRowDataFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud protegida exitosamente.' });
      yield put(hideRowDataSuccess(response));
    }
  });
}

function* assign() {
  yield takeLatest(assignRequest, function* assignRequestSaga(action) {
    const { ids, pagination, controls } = action.payload;
    const response = yield apiRequest(`api/v1/conciliation-request/operator/assign-requests/`, {
      method: 'post',
      body: JSON.stringify({ ids }),
    });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(assignRequestFail());
    } else {
      defaultSuccessToast({ message: 'Solicitud asignada exitosamente.' });
      yield put(assignRequestSuccess());
      yield put(fetchOperatorRequestsList({ controls, pagination }));
    }
  });
}

function* filters() {
  yield takeLatest(getInitialData, function* getInitialDataSaga() {
    const response = yield apiRequest('api/v1/conciliation-request/operator/filters-data', {
      method: 'get',
    });
    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(getInitialDataFail());
    } else {
      yield put(getInitialDataSuccess(response));
    }
  });
}

function* downloadTableReport() {
  yield takeLatest(getTableReport, function* downloadTableReportSaga(action) {
    yield put(setLoader('downloadTableReportButtonId', true));
    const { controls } = action.payload;
    try {
      const csrfToken = window.document.cookie
        .split('; ')
        .find((cookie) => cookie.trim().startsWith('XSRF-TOKEN'));
      const session = getItemInStorage('user');

      const headers = {
        'Content-Type': 'blob',
        'X-CSRF-Token': csrfToken?.split('=')[1],
        Authorization: `Bearer ${session.token}`,
      };

      const response = yield call(
        fetch,
        `${window.location.origin}/${craftReportParams(controls)}`,
        {
          method: 'GET',
          responseType: 'arraybuffer',
          headers,
        },
      );

      console.log('response', response);

      const myBlob = yield response.blob();

      const url = window.URL.createObjectURL(
        new Blob([myBlob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );

      const currentDate = new Date();
      const formatter = new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const formattedDate = formatter.format(currentDate);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `reporte_tabla_${formattedDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      defaultSuccessToast({ message: 'Reporte descargado exitosamente.' });
      yield put(getTableReportSuccess());
    } catch (error) {
      defaultErrorToast({ message: 'Reporte no pudo ser descargado.' });
      yield put(getTableReportFail());
    }
    yield put(setLoader('downloadTableReportButtonId', false));
  });
}

function* downloadClientReport() {
  yield takeLatest(getClientReport, function* downloadClientReportSaga() {
    yield put(setLoader('downloadClientReportButtonId', true));
    try {
      const csrfToken = window.document.cookie
        .split('; ')
        .find((cookie) => cookie.trim().startsWith('XSRF-TOKEN'));
      const session = getItemInStorage('user');

      const headers = {
        'Content-Type': 'blob',
        'X-CSRF-Token': csrfToken?.split('=')[1],
        Authorization: `Bearer ${session.token}`,
      };

      const response = yield call(
        fetch,
        `${window.location.origin}/api/v1/conciliation-request/operator/download-report-client`,
        {
          method: 'GET',
          responseType: 'arraybuffer',
          headers,
        },
      );

      const myBlob = yield response.blob();

      const url = window.URL.createObjectURL(
        new Blob([myBlob], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      );

      const currentDate = new Date();
      const formatter = new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const formattedDate = formatter.format(currentDate);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `reporte_cliente_${formattedDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      defaultSuccessToast({ message: 'Reporte descargado exitosamente.' });
      yield put(getTableReportSuccess());
    } catch (error) {
      defaultErrorToast({ message: 'Reporte no pudo ser descargado.' });
      yield put(getTableReportFail());
    }
    yield put(setLoader('downloadClientReportButtonId', false));
  });
}

export default function* OperatorRequestsSaga() {
  yield spawn(list);
  yield spawn(reveal);
  yield spawn(hide);
  yield spawn(assign);
  yield spawn(filters);
  yield spawn(downloadTableReport);
  yield spawn(downloadClientReport);
}
