import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import ColorCodedChip from 'content/shared/ColorCodedChip/ColorCodedChip';
import { IconButton, Tooltip, Typography } from '@mui/material';
import { FindInPage } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditDocument from './EditDocument';

function StyledTableCell({ text, sx = {}, align = 'left' }) {
  return (
    <TableCell
      align={align}
      sx={{
        backgroundColor: 'black',
        color: 'white',
        pt: 2,
        pb: 2,
        fontWeight: 'semibold',
        ...sx,
      }}>
      {text}
    </TableCell>
  );
}

function DesktopTable({ rows }) {
  const navigate = useNavigate();
  return (
    <TableContainer>
      <Table aria-label="request table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ borderTopLeftRadius: 8, pr: 0 }} text="ID de solicitud" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Fecha de solicitud" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Vehículo" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Patente" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Tipo de solicitud" />
            <StyledTableCell sx={{ pl: 0, pr: 0 }} text="Estado" align="center" />
            <StyledTableCell
              align="center"
              sx={{ borderTopRightRadius: 8, pl: 0 }}
              text="Acciones"
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>
                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                  No hay solicitudes
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}>
              <TableCell align="left" sx={{ pr: 0, pt: 0.8, pb: 0.8 }}>
                {row.id}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {new Date(row.createdAt).toLocaleDateString('es-CL')}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.vehicleData}
              </TableCell>
              <TableCell align="left" sx={{ pl: 0, pr: 2 }}>
                {row.vehicleLicensePlate}
              </TableCell>
              <TableCell align="left" sx={{ p: 0 }}>
                {row.requestType}
              </TableCell>
              <TableCell align="center" sx={{ p: 0 }}>
                <ColorCodedChip state={row.requestStatus} />
              </TableCell>
              <TableCell align="center" sx={{ pl: 0, pt: 0.8, pb: 0.8 }}>
                <IconButton
                  aria-label="see request"
                  color="primary"
                  onClick={() => {
                    navigate(`/requests/${row.id}`);
                  }}>
                  <Tooltip title="Ver solicitud" arrow>
                    <FindInPage />
                  </Tooltip>
                </IconButton>
                <IconButton
                  aria-label="amend request"
                  color="primary"
                  onClick={() => {
                    navigate(`/amendment/${row.id}`);
                  }}
                  disabled={row.requestStatus !== 'Objetada' || row.hasAmendment}>
                  <Tooltip title="Enmendar solicitud" arrow>
                    <EditDocument color="inherit" />
                  </Tooltip>
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DesktopTable;
