const BeneficiaryAmendmentTypes = {
  FETCH_FORM_INITIAL_DATA: '[BeneficiaryAmendment] FETCH_FORM_INITIAL_DATA',
  FETCH_FORM_INITIAL_DATA_SUCCESS: '[BeneficiaryAmendment] FETCH_FORM_INITIAL_DATA_SUCCESS',
  FETCH_FORM_INITIAL_DATA_FAIL: '[BeneficiaryAmendment] FETCH_FORM_INITIAL_DATA_FAIL',
  CREATE_BENEFICIARY_AMENDMENT: '[BeneficiaryAmendment] CREATE_BENEFICIARY_AMENDMENT',
  CREATE_BENEFICIARY_AMENDMENT_SUCCESS:
    '[BeneficiaryAmendment] CREATE_BENEFICIARY_AMENDMENT_SUCCESS',
  CREATE_BENEFICIARY_AMENDMENT_FAIL: '[BeneficiaryAmendment] CREATE_BENEFICIARY_AMENDMENT_FAIL',
  SET_CONTROL: '[BeneficiaryAmendment] SET_CONTROL',
  CLEAR_SCREEN_DATA: '[BeneficiaryAmendment] CLEAR_SCREEN_DATA',
  FETCH_REQUEST: '[BeneficiaryAmendment] FETCH_REQUEST',
  FETCH_REQUEST_SUCCESS: '[BeneficiaryAmendment] FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_FAIL: '[BeneficiaryAmendment] FETCH_REQUEST_FAIL',
};
export default BeneficiaryAmendmentTypes;
