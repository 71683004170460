import { takeLatest, spawn, put, call } from 'redux-saga/effects';

/* Project */
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import api from 'utils/api';
import {
  login,
  loginSuccess,
  loginFail,
  googleLogin,
  googleLoginSuccess,
  googleLoginFail,
  logout,
  logoutSuccess,
} from './login.actions';

// eslint-disable-next-line complexity
function* loginUserApi(action) {
  try {
    const body = JSON.stringify(action.payload);
    const response = yield call(api, 'api/v1/auth/login', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem(
        'user',
        JSON.stringify({ token: response.token, userType: response.user.type }),
      );

      yield put(loginSuccess({ user: response.user }));
    } else {
      switch (response.statusCode) {
        case 401:
          yield put(loginFail('Credenciales incorrectas.'));
          break;
        case 429:
          yield put(loginFail('Demasiadas solicitudes, intenta nuevamente más tarde.'));
          break;
        default:
          yield put(loginFail('Error desconocido, por favor intenta nuevamente.'));
          break;
      }
      defaultErrorToast({ message: response.message });
    }
  } catch (error) {
    // Manejo de errores de red o del servidor
    yield put(loginFail('Error de red o servidor, por favor intenta nuevamente.'));
    defaultErrorToast({ message: 'Error de red o servidor, por favor intenta nuevamente.' });
  }
}

function* loginUser() {
  yield takeLatest(login, loginUserApi);
}

function* loginUserByGoogle() {
  yield takeLatest(googleLogin, function* fetchGoogleLogin(action) {
    const body = JSON.stringify(action.payload);

    const response = yield api('api/v1/auth/google', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          token: response.token,
        }),
      );

      yield put(
        googleLoginSuccess({
          user: response.user,
        }),
      );
    } else {
      yield put(googleLoginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

const removeGoogleId = ({ user }) => {
  if (window.google) {
    window.google.accounts.id.revoke(user.email);
  }
};

function* logoutUser() {
  yield takeLatest(logout, function* logoutLocalUser({ payload }) {
    const { user, signedByGoogle } = payload;

    if (signedByGoogle) {
      removeGoogleId({ signedByGoogle, user });
    }

    localStorage.clear();

    yield put(logoutSuccess());
  });
}

export default function* LoginSaga() {
  yield spawn(loginUser);
  yield spawn(logoutUser);
  yield spawn(loginUserByGoogle);
}
