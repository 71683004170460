import { spawn, takeLatest, put } from 'redux-saga/effects';

import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import { fetchUserList, fetchUserListFail, fetchUserListSuccess } from './userMaintainer.actions';

function craftParams(controls, pagination) {
  const { page, pageSize } = pagination;
  const { userId, rut, name } = controls;
  return `api/v1/users?page=${page}&pageSize=${pageSize}&userId=${userId}&name=${name}&rut=${rut}`;
}

function* list() {
  yield takeLatest(fetchUserList, function* fetchUserListSaga(action) {
    const { controls, pagination } = action.payload;
    const response = yield apiRequest(craftParams(controls, pagination), { method: 'get' });

    if (response.error) {
      defaultErrorToast({ message: response.message });
      yield put(fetchUserListFail());
    } else {
      yield put(fetchUserListSuccess(response));
    }
  });
}

export default function* UserMaintainerSaga() {
  yield spawn(list);
}
