import { createAction } from '@reduxjs/toolkit';

import RegisterTypes from './register.types';

export const setControls = createAction(RegisterTypes.SET_CONTROLS);

export const setControl = createAction(RegisterTypes.SET_CONTROL, ({ name, value }) => ({
  payload: { [name]: value },
}));

export const resetControls = createAction(RegisterTypes.RESET_CONTROLS);

export const createUser = createAction(
  RegisterTypes.CREATE_USER,
  ({ email, name, password, type, navigate }) => ({
    payload: { email, name, password, type, navigate },
  }),
);

export const createUserSuccess = createAction(RegisterTypes.CREATE_USER_SUCCESS);

export const createUserFail = createAction(RegisterTypes.CREATE_USER_FAIL);

const RegisterActions = {
  setControl,
  setControls,
  resetControls,
  createUser,
  createUserSuccess,
  createUserFail,
};

export default RegisterActions;
