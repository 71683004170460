import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddTask, Analytics, Dvr, FileDownload } from '@mui/icons-material';
import BeneficiaryRequestSkeleton from 'content/features/beneficiary-requests/components/BeneficiaryRequestsSkeleton';
import Filters from 'content/features/operator-requests/components/Filters';
import NewAssignmentButton from 'content/features/operator-requests/components/NewAssignmentButton';
import OperatorRequestList from 'content/features/operator-requests/components/OperatorRequestList';
import {
  assignRequest,
  clearFilters,
  createRequestCheckboxesControls,
  fetchOperatorRequestsList,
  getClientReport,
  getInitialData,
  getTableReport,
  setControl,
  setPagination,
} from 'content/features/operator-requests/operatorRequests.actions';
import ConfirmationDialog from 'content/shared/Dialogs/ConfirmationDialog';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import {
  formatRut,
  validateDate,
  validateDateRange,
  validateLicensePlate,
  validateRut,
} from 'utils/helper';

// eslint-disable-next-line complexity
function OperatorRequests() {
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.operatorRequests.requests);
  const { loaders, pagination, controls, initialData } = useSelector(
    (state) => state.operatorRequests,
  );
  /*   const { downloadClientReportButtonId, downloadTableReportButtonId } = useSelector(
    (state) => state.app.loaders,
  ); */

  const { individualChecks, confirmationModal } = controls;
  const [errors, setErrors] = useState({
    requestId: false,
    startDate: false,
    endDate: false,
    rut: false,
    licensePlate: false,
  });

  useEffect(() => {
    dispatch(fetchOperatorRequestsList({ controls, pagination }));
  }, [pagination.page, pagination.pageSize]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => !row.operatorRequestName);
    const checkBoxes = filteredRows.map((row) => ({
      id: row.id,
    }));
    dispatch(createRequestCheckboxesControls(checkBoxes));
  }, [rows, pagination.page, pagination.pageSize]);

  useEffect(() => {
    dispatch(getInitialData());
  }, []);

  const openConfirmationModal = () => {
    if (Object.values(individualChecks).includes(true)) {
      dispatch(setControl({ name: 'confirmationModal', value: true }));
    } else {
      dispatch(setControl({ name: 'checksError', value: true }));
    }
  };

  const closeConfirmationModal = () => {
    dispatch(setControl({ name: 'confirmationModal', value: false }));
  };

  const handleSubmitConfirmation = () => {
    const filteredIndividualChecks = Object.keys(controls.individualChecks).filter(
      (key) => controls.individualChecks[key],
    );

    dispatch(assignRequest({ ids: filteredIndividualChecks, pagination, controls }));
  };

  // eslint-disable-next-line complexity
  const handleChange = (event) => {
    const { name, value } = event.target;

    const actions = {
      rut: (val) => formatRut(val),
    };

    const action = actions[name];
    const newValue = action ? action(value) : value;

    dispatch(setControl({ name, value: newValue }));

    switch (name) {
      case 'requestId':
        setErrors({ ...errors, requestId: !Number.isInteger(Number(value)) && value !== '' });
        break;
      case 'startDate':
        setErrors({ ...errors, startDate: !validateDate(value) });
        break;
      case 'endDate':
        setErrors({ ...errors, endDate: !validateDate(value) });
        if (controls.startDate !== '') {
          setErrors({
            ...errors,
            endDate: !validateDate(value) || validateDateRange(controls.startDate, value),
          });
        }
        break;
      case 'rut':
        setErrors({ ...errors, rut: !validateRut(value) && value !== '' });
        break;
      case 'licensePlate':
        setErrors({ ...errors, licensePlate: !validateLicensePlate(value) && value !== '' });
        break;
      default:
        break;
    }
  };

  const handleClear = () => {
    setErrors({
      requestId: false,
      startDate: false,
      endDate: false,
      rut: false,
      licensePlate: false,
    });
    dispatch(clearFilters());
    const clearFiltersData = {
      requestId: '',
      startDate: null,
      endDate: null,
      rut: '',
      licensePlate: '',
      states: '',
      operator: '',
    };
    dispatch(fetchOperatorRequestsList({ controls: clearFiltersData, pagination }));
  };

  const handleSearch = () => {
    if (!Object.values(errors).some((error) => error)) {
      dispatch(setPagination({ name: 'page', value: 0 }));
      dispatch(fetchOperatorRequestsList({ controls, pagination }));
    }
  };

  const handleClose = () => {
    dispatch(setControl({ name: 'reportsMenu', value: false }));
    dispatch(setControl({ name: 'anchorElMenu', value: null }));
  };

  /* const handleClick = (event) => {
    dispatch(setControl({ name: 'reportsMenu', value: event.currentTarget }));
    dispatch(setControl({ name: 'anchorElMenu', value: event.currentTarget }));
  }; */

  const handleDownloadClientReport = () => {
    dispatch(getClientReport());
    handleClose();
  };

  const handleDownloadTable = () => {
    dispatch(getTableReport({ controls }));
    handleClose();
  };

  return loaders.initial ? (
    <BeneficiaryRequestSkeleton />
  ) : (
    <Grid container justifyContent="center" alignItems="center" spacing={4}>
      <MainTitle
        title="Solicitudes"
        subtitle="Solicitudes ingresadas por los usuarios para su revisión."
        icon={<Dvr sx={{ fontSize: 40, marginRight: 1, color: '#86BC25' }} />}
      />
      <Grid item xs={12}>
        <Filters
          controls={controls}
          initialData={initialData}
          onChange={handleChange}
          handleClear={handleClear}
          handleSearch={handleSearch}
          errors={errors}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="flex-end" display="flex">
        <Typography
          variant="h4"
          component="h1"
          sx={{
            display: 'flex',
            alignItems: 'right',
            flexWrap: 'wrap',
            fontSize: '36px',
            fontWeight: 700,
            lineHeight: '49.03px',
          }}>
          <Grid item paddingX={1}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<FileDownload color="primary" sx={{ pr: 1, paddingRight: '2px' }} />}
              sx={{
                minWidth: '135px',
                backgroundColor: '#ffffff',
              }}
              onClick={handleDownloadClientReport}>
              Descargar indicadores
            </Button>
          </Grid>
          <Grid item paddingX={1}>
            <Button
              variant="outlined"
              size="large"
              startIcon={<Analytics color="primary" sx={{ pr: 1, paddingRight: '2px' }} />}
              sx={{
                minWidth: '135px',
                backgroundColor: '#ffffff',
              }}
              onClick={handleDownloadTable}>
              Descargar tabla
            </Button>
          </Grid>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OperatorRequestList rows={rows} />
      </Grid>
      <NewAssignmentButton onClick={openConfirmationModal} />
      <ConfirmationDialog
        closeConfirmationModal={closeConfirmationModal}
        confirmationModal={confirmationModal}
        title="Asignar"
        message="¿Estás seguro de que deseas asignarte las solicitudes seleccionadas? Esta acción no se puede deshacer."
        icon={<AddTask sx={{ fontSize: '24px', marginRight: 1, color: '#86BC25' }} />}
        handleSubmitConfirmation={handleSubmitConfirmation}
      />
    </Grid>
  );
}

export default OperatorRequests;
