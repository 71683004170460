import React, { useCallback, useEffect } from 'react';
import { Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import VehicleForm from 'content/features/beneficiary-request/components/VehicleForm';
import BeneficiaryForm from 'content/features/beneficiary-request/components/BeneficiaryForm';
import PaymentMethodForm from 'content/features/beneficiary-request/components/PaymentMethodForm';
import PersonalDocumentsForm from 'content/features/beneficiary-request/components/PersonalDocumentsForm';
import TermsAndConditions from 'content/features/beneficiary-request/components/TermsAndConditions';
import {
  clearScreenData,
  createBeneficiaryRequest,
  fetchFormInitialData,
  setControl,
} from 'content/features/beneficiary-request/beneficiaryRequest.actions';
import MainTitle from 'content/shared/MainTitle/MainTitle';
import useForm from 'hooks/useForm';
import { useNavigate } from 'react-router-dom';
import {
  dropZoneNoEmpty,
  formatLettersAndSpaces,
  formatOnlyNumbers,
  formatRut,
  inputLength,
  inputLettersAndSpaces,
  inputNoEmpty,
  inputNumberAndLetters,
  inputOnlyNumbers,
  validateEmail,
  validateLicensePlate,
  validatePhoneNumbers,
  validateRut,
} from 'utils/helper';
import SectionTitle from 'content/shared/SectionTitle/SectionTitle';

// eslint-disable-next-line complexity
function BeneficiaryRequest() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { controls, loaders, initialData } = useSelector((state) => state.beneficiaryRequest);
  const { createBeneficiaryRequestButtonId } = useSelector((state) => state.app.loaders);
  const { accountPaymentMethods } = initialData;
  const { rut, accountPaymentMethod, termsAndConditions, accountHolderRut } = controls;

  const transferId = accountPaymentMethods.find(
    (method) => method.label === 'TRANSFERENCIA',
  )?.value;

  useEffect(() => {
    dispatch(fetchFormInitialData());

    return () => {
      dispatch(clearScreenData());
    };
  }, []);

  useEffect(() => {
    if (loaders.requestCreated) navigate('/requests');
  }, [loaders.requestCreated]);

  const onChangeText = useCallback((id, value) => {
    const actions = {
      rut: (val) => formatRut(val),
      accountHolderRut: (val) => formatRut(val),
      licensePlate: (val) => val.toUpperCase(),
      vin: (val) => val.toUpperCase(),
      brand: (val) => {
        dispatch(setControl({ name: 'model', value: '' }));
        dispatch(setControl({ name: 'year', value: '' }));
        return val;
      },
      model: (val) => {
        dispatch(setControl({ name: 'year', value: '' }));
        return val;
      },
      names: (val) => formatLettersAndSpaces(val),
      paternalSurname: (val) => formatLettersAndSpaces(val),
      maternalSurname: (val) => formatLettersAndSpaces(val),
      accountHolderName: (val) => formatLettersAndSpaces(val),
      accountHolderNumber: (val) => formatOnlyNumbers(val),
      phone: (val) => formatOnlyNumbers(val),
      region: (val) => {
        dispatch(setControl({ name: 'city', value: '' }));
        dispatch(setControl({ name: 'commune', value: '' }));
        return val;
      },
      city: (val) => {
        dispatch(setControl({ name: 'commune', value: '' }));
        return val;
      },
      accountPaymentMethod: (val) => {
        dispatch(setControl({ name: 'accountBank', value: '' }));
        dispatch(setControl({ name: 'accountType', value: '' }));
        dispatch(setControl({ name: 'accountHolderNumber', value: '' }));
        return val;
      },
    };

    const action = actions[id];
    const newValue = action ? action(value) : value;

    dispatch(setControl({ name: id, value: newValue }));
  }, []);

  const { onChange, onSubmit, errors, validate } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      vin: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un VIN',
        },
        {
          check: inputNumberAndLetters,
          message: 'El VIN ingresado no es válido',
        },
        {
          check: (value) => value.length === 17,
          message: 'El VIN ingresado no es válido',
        },
      ],
      licensePlate: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar una patente',
        },
        {
          check: validateLicensePlate,
          message: 'La patente ingresada no es válida',
        },
        {
          check: (value) => value.length === 6,
          message: 'La patente ingresada no es válida',
        },
      ],
      brand: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una marca',
        },
      ],
      model: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un modelo',
        },
      ],
      year: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un año',
        },
      ],
      rut: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un RUT',
        },
        {
          check: validateRut,
          message: 'El RUT ingresado no es válido',
        },
      ],
      names: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un nombre',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El nombre ingresado no es válido',
        },
        {
          check: inputLength,
          message: 'El nombre no puede exceder los 255 caracteres',
        },
      ],
      paternalSurname: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un apellido paterno',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El apellido ingresado no es válido',
        },
        {
          check: inputLength,
          message: 'El apellido no puede exceder los 255 caracteres',
        },
      ],
      maternalSurname: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un apellido materno',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El apellido ingresado no es válido',
        },
        {
          check: inputLength,
          message: 'El apellido no puede exceder los 255 caracteres',
        },
      ],
      address: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar una dirección',
        },
        {
          check: inputLength,
          message: 'La dirección no puede exceder los 255 caracteres',
        },
      ],
      region: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una región',
        },
      ],
      city: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una ciudad',
        },
      ],
      commune: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar una comuna',
        },
      ],
      phone: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un teléfono',
        },
        {
          check: inputOnlyNumbers,
          message: 'El teléfono ingresado no es válido',
        },
        {
          check: validatePhoneNumbers,
          message: 'El teléfono ingresado no es válido',
        },
      ],
      email: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un correo',
        },
        {
          check: validateEmail,
          message: 'El correo ingresado no es válido',
        },
      ],
      accountPaymentMethod: [
        {
          check: inputNoEmpty,
          message: 'Debe seleccionar un método de pago',
        },
      ],
      accountHolderName: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un nombre',
        },
        {
          check: inputLettersAndSpaces,
          message: 'El nombre ingresado no es válido',
        },
      ],
      accountHolderRut: [
        {
          check: inputNoEmpty,
          message: 'Debe ingresar un RUT',
        },
        {
          check: validateRut,
          message: 'El RUT ingresado no es válido',
        },
        {
          check: (value) => formatRut(value) === rut,
          message: 'El RUT ingresado DEBE ser igual al RUT solicitante',
        },
      ],
      accountBank: [
        {
          check: (value) => inputNoEmpty(value) || transferId !== accountPaymentMethod,
          message: 'Debe seleccionar un banco',
        },
      ],
      accountType: [
        {
          check: (value) => inputNoEmpty(value) || transferId !== accountPaymentMethod,
          message: 'Debe seleccionar un tipo de cuenta',
        },
      ],
      accountHolderNumber: [
        {
          check: (value) => inputNoEmpty(value) || transferId !== accountPaymentMethod,
          message: 'Debe ingresar un número de cuenta',
        },
        {
          check: (value) => inputOnlyNumbers(value) || transferId !== accountPaymentMethod,
          message: 'El número de cuenta ingresado no es válido',
        },
      ],
      frontIdentityDocument: [
        {
          check: dropZoneNoEmpty,
          message: 'Debe adjuntar un archivo',
        },
      ],
      backIdentityDocument: [
        {
          check: dropZoneNoEmpty,
          message: 'Debe adjuntar un archivo',
        },
      ],
      carInscriptionDocument: [
        {
          check: dropZoneNoEmpty,
          message: 'Debe adjuntar un archivo',
        },
      ],
      termsAndConditions: [
        {
          check: (value) => value,
          message: 'Debe aceptar los términos y condiciones',
        },
      ],
    },
  });

  useEffect(() => {
    if (rut) {
      validate('accountHolderRut', accountHolderRut);
    }
  }, [rut]);

  const handleSubmit = (event) => {
    event.preventDefault();

    onSubmit(() => {
      dispatch(createBeneficiaryRequest(controls));
    });
  };

  return (
    <Grid container spacing={2}>
      <MainTitle
        title="Nueva solicitud"
        subtitle={
          <>
            Ingresa los datos que te solicitamos a continuación para ingresar tu nueva solicitud.
            Todos los campos son <b>obligatorios</b>.
          </>
        }
      />
      <SectionTitle
        title="Datos del vehículo"
        sx={{
          mt: 4,
        }}
      />
      <Grid container item spacing={{ md: 4, xs: 2 }} sx={{ mb: 6 }}>
        <VehicleForm
          errors={errors}
          onChange={onChange}
          controls={controls}
          initialData={initialData}
        />
      </Grid>
      <SectionTitle title="Datos del solicitante" />
      <Grid container item spacing={{ md: 4, xs: 2 }} sx={{ mb: 6 }}>
        <BeneficiaryForm
          onChange={onChange}
          errors={errors}
          controls={controls}
          initialData={initialData}
        />
      </Grid>
      <SectionTitle title="Método de pago" />
      <Grid container item spacing={{ md: 4, xs: 2 }} sx={{ mb: 6 }}>
        <PaymentMethodForm
          onChange={onChange}
          errors={errors}
          controls={controls}
          initialData={initialData}
        />
      </Grid>
      <SectionTitle title="Documentos adjuntos" />
      <PersonalDocumentsForm onChange={onChange} errors={errors} />
      <TermsAndConditions
        onChange={onChange}
        errors={errors}
        termsAndConditions={termsAndConditions}
      />
      <Grid
        container
        item
        direction={{ md: 'row', xs: 'column-reverse' }}
        spacing={{ md: 4, xs: 2 }}>
        <Grid container item md={6} justifyContent={{ md: 'flex-start', xs: 'center' }}>
          <Button
            variant="outlined"
            size="large"
            fullWidth={isMobile}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={() => navigate('/requests')}>
            Cancelar solicitud
          </Button>
        </Grid>
        <Grid container item md={6} justifyContent={{ md: 'flex-end', xs: 'center' }}>
          <Button
            id="createBeneficiaryRequestButtonId"
            variant="contained"
            size="large"
            fullWidth={isMobile}
            onClick={handleSubmit}
            sx={{ textTransform: 'none', fontWeight: 'bold' }}
            disabled={createBeneficiaryRequestButtonId || false}
            startIcon={createBeneficiaryRequestButtonId ? <CircularProgress size={15} /> : null}>
            Ingresar solicitud
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BeneficiaryRequest;
