import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

function ConfirmationDialog({
  confirmationModal,
  closeConfirmationModal,
  title,
  icon,
  message,
  handleSubmitConfirmation,
}) {
  return (
    <Dialog open={confirmationModal} onClose={closeConfirmationModal} maxWidth="sm">
      <DialogTitle sx={{ pb: 0, pl: 4.5, pr: 4.5, pt: 4.5, mb: 2 }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            fontSize: '24px',
            fontWeight: 700,
            lineHeight: '49.03px',
          }}>
          {icon} {title}
        </Typography>
        <IconButton
          onClick={closeConfirmationModal}
          sx={{
            position: 'absolute',
            right: 36,
            top: 36,
            fontSize: '24px',
            color: (theme) => theme.palette.grey[500],
          }}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 0, pl: 4.5, pr: 4.5, pb: 4 }}>
        <DialogContentText>
          <Typography
            variant="body2"
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '21.79px',
              textAlign: 'left',
            }}>
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <Stack
        sx={{ pl: 4.5, pr: 4.5, pb: 4.5 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <Button
          variant="outlined"
          sx={{
            color: 'black',
            textTransform: 'none',
            minWidth: '160px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'black',
          }}
          size="large"
          onClick={closeConfirmationModal}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: 'none', minWidth: '149px' }}
          size="large"
          onClick={handleSubmitConfirmation}>
          Asignar
        </Button>
      </Stack>
    </Dialog>
  );
}

export default ConfirmationDialog;
